<template>
    <BoxLayout>
        <div class="alert alert-warning" role="alert" v-if="alertMessage">{{alertMessage}}</div>
        <form v-if="!isSuccess" v-on:submit.prevent="submit" novalidate>
            <div class="mb-3">
                <TextInput
                    label="Password"
                    v-model="v$.password.$model"
                    :v="v$.password"
                    type="password"
                    @on-click="showPasswordHelper = true"
                />
            </div>
            <PasswordModal
            v-show="showPasswordHelper"
            :password="v$.password.$model"
            />
            <div class="mb-3">
                <TextInput
                    label="Confirm password"
                    v-model="v$.password_confirm.$model"
                    :v="v$.password_confirm"
                    type="password"
                />
            </div>
            <button type="submit" class="btn w-100 btn-primary">Submit</button>
        </form>
        <div v-if="isSuccess" class="text-center">
            <p>Your password has been changed.</p>
            <p>Please <router-link to="/login">sign in</router-link> using your email address and new password. </p>
        </div>
    </BoxLayout>
</template>

<script>
    import { ref } from 'vue';
    import api from "@/services/api";
    import TextInput from '@/components/TextInput.vue';
    import BoxLayout from '@/components/BoxLayout.vue';
    import useVuelidate from '@vuelidate/core'
    import { required, sameAs } from '@vuelidate/validators'
    import PasswordModal from '../components/PasswordModal.vue';

    export default {
        props: {
            code: {
                required: true
            }
        },
        data() {
            return {
                password: '',
                password_confirm: '',
                alertMessage: null,
                isSuccess: false,
                showPasswordHelper: false,
            }
        },
        setup () {
            const externalResults = ref({});
            return {
                externalResults,
                v$: useVuelidate({ $externalResults: externalResults }),
            }
        },
        validations () {
            return {
                password: { required },
                password_confirm: { required, sameAs: sameAs(this.password) },
            }
        },
        components: {
            TextInput,
            BoxLayout,
            PasswordModal
        },
        methods: {
            submit: async function () {
                this.alertMessage = '';

                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.request();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }
            },
            request: function () {
                api.post('/api/v1/password-change/' +  this.code, {
                    password: this.password,
                })
                .then(() => {
                    this.isSuccess = true;
                })
                .catch((error) => {
                    if (error.response)
                    {
                        Object.assign(this.externalResults, error.response.data.errors);
                    } 
                    else
                    {
                        this.alertMessage = 'There was an problem changing your password. Please try again later.';
                    }
                });
            },
            checkToken: function () {
                api
                .get('/api/v1/password-reset/' +  this.code)
                .then(() => {
                    // Responded OK
                })
                .catch(() => {
                    // Error finding hash, redirect to home.
                    this.$router.replace({
                        name: 'NotFound',
                        // preserve current path and remove the first char to avoid the target URL starting with `//`
                        params: { pathMatch: this.$route.path.substring(1).split('/') },
                        // preserve existing query and hash if any
                        query: this.$route.query,
                        hash: this.$route.hash,
                    })

                });
            },
        },
        mounted() {
            this.checkToken();
        }
    }
</script>